import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import { pinRestaurant } from "../icons"

import Layout from "../components/layout"
import Map from "../components/map"
import Section from "../components/section"
import Separator from "../components/separator"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const POIs = [
    {
      title: "Sidrería Pichote",
      icon: pinRestaurant,
      lat: 43.368295063906714,
      lng: -5.8679494288426834,
      info: "https://sidreriapichote.com/",
      link: "https://g.page/sidreriapichote?share",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipNgBIQYRZWBGIhzGLaI4LlTPO-tpZk_01QJ9bys=w408-h408-k-no",
    },
    {
      title: "Ronda 14",
      icon: pinRestaurant,
      lat: 43.5553815614517,
      lng: -5.923424663780784,
      info: "https://ronda14.com/",
      link: "https://goo.gl/maps/tFkDGNHRugJt77Np6",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipPjH2dQDiOrKfvUq84CuATBY_RjgGj8mdRJe-iE=w408-h611-k-no",
    },
    {
      title: "El Padrino",
      icon: pinRestaurant,
      lat: 43.54435820392523,
      lng: -5.662664886509125,
      info: "https://www.facebook.com/PadrinoComidaSobreMasa",
      link: "https://goo.gl/maps/26oWuiTUWVL81wsL6",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipPLPvCtn2Ow-_AmqKJT2sQ7y5fPYq4wuXG4ei2e=w408-h544-k-no",
    },
  ]

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Restaurantes</h2>
        <Separator />
        <p>
          No podéis estar por Asturias y no comer como Dios manda! Aquí os
          dejamos recomendaciones de restaurantes.
        </p>
      </Section>

      <Map
        center={{
          lat: 43.4647896,
          lng: -5.7844736,
        }}
        zoom={10}
        POIs={POIs}
        selected={1}
      />
    </Layout>
  )
}
